
<template>
  <div class="foot">
    <div class="foot-left">
      <img class="logo" src="@/assets/img/LOGO@2x.png" alt="" />
      <div class="left-logo">
        <div style="display: flex">
          <div class="imgtext imgzzh">
            <img src="@/assets/img/xiaochengxu.jpg" alt="" />
            如易云小程序
            <div id="imghover">
              <img src="@/assets/img/xiaochengxu.jpg" alt="" />
            </div>
          </div>
          <div class="imgtext gzh">
            <img src="@/assets/img/gongzhonghao.jpg" alt="" />
            如易数科公众号
            <div id="gzhhover">
              <img src="@/assets/img/gongzhonghao.jpg" alt="" />
            </div>
          </div>
        </div>
        添加如易云小程序.如易数科公众号
      </div>
    </div>
    <div class="foot-r">
      <span>产品服务</span>
      <a @click.stop="switchnav('ry')">如易云</a>
      <a @click.stop="switchnav('ky')">积分营销</a>
      <a @click.stop="switchnav('jy')">跨境易货</a>
      <a @click.stop="switchnav('xl')">如易学院</a>
    </div>
    <div class="foot-r">
      <span>关于我们</span>
      <a @click="switchnav('gy')">关于如易数科</a>
      <a @click="switchnav('sj')">大事件</a>
      <a @click="switchnav('lx')">联系我们</a>
    </div>
    <div class="foot-r">
      <span>帮助支持</span>
      <!-- <a>常见问题</a> -->
      <a @click="switchnav('lx')">网站地图</a>
      <a @click="switchnav('flsm')">法律声明</a>
    </div>
    <div class="ffot">
      <span class="phone">联系方式(微信同号)</span>
      <div class="phpne1">18853252266</div>

      <div class="phone">联系地址</div>
      <div class="address">重庆市南岸区通江大道225号全国数字易货总部基地</div>
    </div>
  </div>
  <div class="All">
    <div class="aloz">Copyright © 2016-2025 . All Rights Reserved <a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备19047613号 || 渝ICP备19010762号</a> </div>
  </div>

  <div class="phfoot">
    <div class="foot-left">
      <img class="logoo" src="@/assets/img/LOGO@2x.png" alt="" />
      <div class="left-logoo">
        <div style="display: flex">
          <div class="imgtext">
            <img src="@/assets/img/xiaochengxu.jpg" alt="" />
            如易云小程序
          </div>
          <div class="imgtext">
            <img src="@/assets/img/gongzhonghao.jpg" alt="" />
            如易数科公众号
          </div>
        </div>
        添加如易云小程序.如易数科公众号
      </div>
    </div>
    <div class="ffot">
      <span class="phone">联系方式(微信同号)</span>
      <div class="phpne1">18853252266</div>

      <div class="phone">联系地址</div>
      <div class="address">重庆市南岸区通江大道225号全国数字易货总部基地</div>
    </div>
  </div>
  <div class="headerAll">
    <div class="aloz alliz">Copyright © 2016-2025 . All Rights Reserved <a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备19047613号 || 渝ICP备19010762号</a> </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import "@/style/foot.scss";
const router = useRouter();
const switchnav = (nav) => {
  if (nav === "gy") {
    router.push({
      path: "/cn/about",
      query: { about: 0 },
    });
  } else if (nav === "sj") {
    router.push({
      path: "/cn/about",
      query: { about: 2 },
    });
  } else if (nav === "lx") {
    router.push({
      path: "/cn/about",
      query: { about: 3 },
    });
  } else if (nav === "ry") {
    router.push({
      path: "/cn/product",
      query: { content: 0 },
    });
  } else if (nav === "jy") {
    router.push({
      path: "/cn/product",
      query: { content: 1 },
    });
  } else if (nav === "ky") {
    router.push({
      path: "/cn/product",
      query: { content: 2 },
    });
  } else if (nav === "xl") {
    router.push({
      path: "/cn/product",
      query: { content: 4 },
    });
  }else if(nav === "flsm"){
    router.push({
      path: "/cn/statement"
    });
  }
};
</script>
  
<style lang="scss">
@media (max-width: 760px) {
  .phfoot .foot-left .left-logoo .imgtext {
    width: 75px;
    padding: 15px 4px;
  }
}
</style>