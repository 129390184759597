<template>
  <router-view />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
</style>
