// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
  {
    path: '/cn',
    name: 'home',
    component: HomeView,
    meta: {
      title: '如易数科-全球新型经济模式数字易货加速平台'
    },
    children: [
      {
        path: '',
        name: 'index',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "index" */ '../views/HaIndex.vue'),
      },
      {
        path: 'product',
        name: 'product',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
      }, 
      {
        path: 'programme',
        name: 'programme',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/programme.vue')
      },
      {
        path: 'about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
      },
      {
        path: 'join',
        name: 'join',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/join.vue')
      },
      {
        path: 'details',
        name: 'details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/details.vue')
      },
      {
        path: 'more',
        name: 'more',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/more.vue')
      },
      {
        path: 'statement',
        name: 'statement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "statement" */ '../views/statement.vue')
      }
    ],
   
  },
  {
    path: '/',
    name: 'enindex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EN/enindex'),
    meta: {
      title: 'Rubarter-Global New Economic Model Digital Barter Acceleration Platform'
    },
    children: [
      {
        path: '',
        name: 'enhome',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enhome'),
      },
      {
        path: 'enproduct',
        name: 'enproduct',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enproduct'),
      },
      {
        path: 'enprogramme',
        name: 'enprogramme',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enprogramme'),
      },
      {
        path: 'enabout',
        name: 'enabout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enabout'),
      },
      {
        path: 'enjoin',
        name: 'enjoin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enjoin'),
      },
      {
        path: 'endetails',
        name: 'endetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/endetails')
      },
      {
        path: 'enmore',
        name: 'enmore',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EN/enmore')
      },
      {
        path: 'enstatement',
        name: 'enstatement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "statement" */ '../views/EN/enstatement')
      }

    ]
  },
]

// const router = createRouter({
//   history: createWebHashHistory(), // 使用 createWebHashHistory 而不是 createWebHistory
//   routes
// });
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 添加导航守卫
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // 滚动到页面顶部
    // 检查目标路由的 meta 是否包含 title 字段
    if (to.meta && to.meta.title) {
      // 如果有，更新页面标题为目标路由的 title
      document.title = to.meta.title;
    } else {
      // 如果目标路由没有设置 title，则使用默认标题或者其他逻辑
      document.title = '如易数科-全球新型经济模式数字易货加速平台';
    }
  next(); // 继续导航
});

export default router
