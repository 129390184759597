
<template>
  <div id="head" :style="redStyle">
    <div class="qheader" :class="{ active: isProductLinkHover }">
      <div>
        <img class="logo" src="@/assets/img/logo白色 1@2x.png" alt="" />
      </div>
      <nav class="navbar">
        <router-link to="/cn">首页</router-link>
        <a
          :class="{ 'router-link-exact-active': $route.path === '/cn/product' }"
          @mouseover="isProductLinkHover = true"
          @mouseout="isProductLinkHover = false"
          class="product-link"
          @click="switchnav('ry')"
          >产品服务
          <div class="navvv">
            <div class="rui" @click.stop="switchnav('ry')">
              <div
                :class="{ 'selected-hena': $route.query.content === '0' }"
                class="hena"
              >
                如易云
              </div>
              <div class="hhaa">为易货企业赋能加速</div>
            </div>
            <div class="rui yh" @click.stop="switchnav('jf')">
              <div
                :class="{ 'selected-hena': $route.query.content === '1' }"
                class="hena"
              >
                跨境易货
              </div>
              <div class="hhaa">跨境易货首选的数字易货加速平台</div>
            </div>
            <div class="rui jjjf" @click.stop="switchnav('kj')">
              <div
                :class="{ 'selected-hena': $route.query.content === '2' }"
                class="hena"
              >
                积分营销
              </div>
              <div class="hhaa">数字增值应用平台</div>
            </div>
            <div class="rui iibb" @click.stop="switchnav('bdsh')">
              <div
                :class="{ 'selected-hena': $route.query.content === '3' }"
                class="hena"
              >
                本地生活
              </div>
              <div class="hhaa">生活服务一应俱全</div>
            </div>
            <div class="rui rrr" @click.stop="switchnav('ryxy')">
              <div
                :class="{ 'selected-hena': $route.query.content === '4' }"
                class="hena"
              >
                如易学院
              </div>
              <div class="hhaa">拓宽易货从业者的认知边界</div>
            </div>
          </div>
        </a>
        <a
          :class="{ 'router-link-exact-active': $route.path === '/cn/programme' }"
          class="product-link"
          @mouseover="isProductLinkHover = true"
          @mouseout="isProductLinkHover = false"
          @click="programmenav('minor')"
          >解决方案
          <div class="programmenavvv">
            <div class="rui zxx jjq" @click.stop="programmenav('minor')">
              <div
                :class="{ 'selected-hena': $route.query.solve === '0' }"
                class="hena"
              >
                中小企业解决方案
              </div>
              <div class="hhaa">企业商务馈赠解决方案</div>
            </div>
            <div class="rui daa jjq" @click.stop="programmenav('large')">
              <div
                :class="{ 'selected-hena': $route.query.solve === '1' }"
                class="hena"
              >
                大企业解决方案
              </div>
              <div class="hhaa">大企业数字易货平台</div>
            </div>
            <div class="rui dff jjq" @click.stop="programmenav('programme')">
              <div
                :class="{ 'selected-hena': $route.query.solve === '2' }"
                class="hena"
              >
                地方政府解决方案
              </div>
              <div class="hhaa">化债逻辑及数字易货化债解决方案</div>
            </div>
          </div>
        </a>
        <router-link to="/cn/about">关于我们</router-link>
        <!-- <router-link to="/join">加入我们</router-link> -->
        <a @click="jiaru">加入我们</a>
      </nav>
      <div class="lang">
        <span @click="switchToLanguage('en')">EN</span> |
        <span @click="switchToLanguage('zh')">中文</span>
      </div>
    </div>
    <div class="headder">
      <div>
        <img class="logo" src="@/assets/img/logo白色 1@2x.png" alt="" />
      </div>
      <div class="phhea" @click="toggleNav">
        <img class="logo1" src="@/assets/img/xvic.png" alt="" />
        <transition name="fade">
          <div class="phnav" v-show="showNav">
            <ul>
              <li><router-link to="/cn">首页</router-link></li>
              <li>
                <a
                  @click.stop="isShow = !isShow"
                  :class="{
                    'router-link-exact-active': $route.path === '/cn/product',
                  }"
                  >产品服务
                  <div class="phoneswitnav" v-show="isShow">
                    <div
                      :class="{ 'selected-hena': $route.query.content === '0' }"
                      @click="switchnav('ry')"
                    >
                      如易云
                    </div>
                    <div
                      :class="{ 'selected-hena': $route.query.content === '1' }"
                      @click="switchnav('jf')"
                    >
                      跨境易货
                    </div>
                    <div
                      :class="{ 'selected-hena': $route.query.content === '2' }"
                      @click="switchnav('kj')"
                    >
                      积分营销
                    </div>
                    <div
                      :class="{ 'selected-hena': $route.query.content === '3' }"
                      @click="switchnav('bdsh')"
                    >
                      本地生活
                    </div>
                    <div
                      :class="{ 'selected-hena': $route.query.content === '4' }"
                      @click="switchnav('ryxy')"
                    >
                      如易学院
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  @click.stop="progisShow = !progisShow"
                  :class="{
                    'router-link-exact-active': $route.path === '/cn/programme',
                  }"
                  >解决方案
                  <div class="phoneswitnav" v-show="progisShow">
                    <div
                    :class="{ 'selected-hena': $route.query.solve === '0' }"
                    @click="programmenav('minor')">中小企业解决方案</div>
                    <div 
                    :class="{ 'selected-hena': $route.query.solve === '1' }"
                    @click="programmenav('large')">大企业解决方案</div>
                    <div 
                    :class="{ 'selected-hena': $route.query.solve === '2' }"
                    @click="programmenav('programme')">
                      地方政府解决方案
                    </div>
                  </div>
                </a>
              </li>
              <li><router-link to="/cn/about">关于我们</router-link></li>
              <!-- <li><router-link to="/join">加入我们</router-link></li> -->
              <a @click="jiaru">加入我们</a>
            </ul>
            <div class="lang">
              <span @click="switchToLanguage('en')">EN</span> |
              <span @click="switchToLanguage('zh')">中文</span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script setup>
import "@/style/header.scss";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
const isProductLinkHover = ref(false);
const isShow = ref(false);
const progisShow = ref(false);
const router = useRouter();
const showNav = ref(false);
const redStyle = ref({ position: "static" });
const jiaru =()=>{
  alert('敬请期待~')
};
const switchnav = (nav) => {
  showNav.value = false;
  if (nav === "ry") {
    router.push({
      path: "/cn/product",
      query: { content: 0 },
    });
  } else if (nav === "jf") {
    router.push({
      path: "/cn/product",
      query: { content: 1 },
    });
  } else if (nav === "kj") {
    router.push({
      path: "/cn/product",
      query: { content: 2 },
    });
  } else if (nav === "bdsh") {
    router.push({
      path: "/cn/product",
      query: { content: 3 },
    });
  } else if (nav === "ryxy") {
    router.push({
      path: "/cn/product",
      query: { content: 4 },
    });
  }
};
const programmenav = (type) => {
  showNav.value = false;
  if (type === "minor") {
    router.push({
      path: "/cn/programme",
      query: { solve: 0 },
    });
  } else if (type === "large") {
    router.push({
      path: "/cn/programme",
      query: { solve: 1 },
    });
  } else if (type === "programme") {
    router.push({
      path: "/cn/programme",
      query: { solve: 2 },
    });
  }
};
const toggleNav = () => {
  showNav.value = !showNav.value;
};
const switchToLanguage = (language) => {
  if (language === "en") {
    router.push("/");
  } else if (language === "zh") {
    router.push("/cn");
  }
};
onMounted(() => {
  window.addEventListener("scroll", function () {
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop < 100) {
      redStyle.value = { position: "static" };
    } else {
      redStyle.value = {
        boxShadow: "1px 0px 4px #292C33",
        position: "fixed",
        top: "0",
        background: "#000",
        width: "100%",
        zIndex: "999",
      };
    }
  });
});
</script>
<style lang="scss">
</style>