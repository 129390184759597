import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import AnimatedNumber from 'animated-number-vue3'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(AnimatedNumber)
app.config.globalProperties.$axios = axios

app.mount('#app')
